<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        Pourquoi un crayon a mine est utilisé plutôt qu’un stylo pour dessiner sur une plaque CCM?
      </p>

      <v-radio-group v-model="inputs.question1Value" class="ml-6 mb-n6" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
          :label="option.text"
        >
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'uOttawa1321Prelab1Q10Fr',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question1Value: null,
      },
      options: [
        {text: 'L’encre est soluble dans les solvants organiques', value: 'soluble'},
        {text: 'Tu veux pouvoir effacer au besoin', value: 'erasing'},
        {
          text: 'L’encre aurait une influence sur la migration des composés organiques',
          value: 'inkInfluenceMigration',
        },
        {text: 'Un stylo risquerait davantage d’abimer le gel de silice', value: 'moreChipping'},
      ],
    };
  },
};
</script>
